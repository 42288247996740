/**
 * File generated by js-routes 2.2.0
 * Based on Rails 6.1.4.4 routes of OnePortal::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !null || !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    object[part] = routes;
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accounts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accounts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contacts/:id/add_delegate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_delegate_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_delegate"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/:id/add_delegate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_delegate_contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_delegate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/contacts/ajax_select(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajax_select_punchout_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"ajax_select"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/contacts/ajax_select(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ajax_select_punchout_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"ajax_select"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/messages/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/messages/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/accessories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_accessories_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accessories"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/accessories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_accessories = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accessories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_account_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_account = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_accounts_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_accounts = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/accounts/for_dropdown(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_accounts_for_dropdown_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"for_dropdown"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/accounts/for_dropdown(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_accounts_for_dropdown = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"for_dropdown"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/api_tokens/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_api_token_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"api_tokens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/api_tokens/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_api_token = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"api_tokens"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/api_tokens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_api_tokens_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"api_tokens"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/api_tokens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_api_tokens = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"api_tokens"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/approvals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_approval_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/approvals/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_approval = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/approvals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_approvals_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"approvals"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/approvals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_approvals = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"approvals"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/bills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_bill_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/bills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_bill = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/bills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_bills_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"bills"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/bills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_bills = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"bills"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/bill_invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_bill_invoices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"bills"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/carriers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_carrier_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"carriers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/carriers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_carrier = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"carriers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/carriers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_carriers_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"carriers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/carriers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_carriers = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"carriers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/cart_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_cart_item_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"cart_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/cart_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_cart_item = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"cart_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/catalog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/catalog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/cold_inventories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_cold_inventories_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"cold_inventories"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/cold_inventories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_cold_inventories = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"cold_inventories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contact_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contact = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/contacts/active_employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_active_employees_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"active_employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contacts/active_employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_active_employees = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"active_employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contacts/contact_communications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_contact_communications_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"contact_communications"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contacts/contact_communications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_contact_communications = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"contact_communications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contacts/contact_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_contact_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"contact_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contacts/contact_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_contact_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"contact_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contacts/deactivate_contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_deactivate_contacts_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"deactivate_contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contacts/deactivate_contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_deactivate_contacts = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"deactivate_contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contacts/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_employees_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contacts/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_employees = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contacts/is_entitled(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_is_entitled_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"is_entitled"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contacts/is_entitled(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contacts_is_entitled = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"is_entitled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/custom_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/custom_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/custom_reports/bills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_bills_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"bills"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/custom_reports/bills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_bills = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"bills"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/custom_reports/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_employees_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/custom_reports/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_employees = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/custom_reports/inventory(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_inventory_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"inventory"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/custom_reports/inventory(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_inventory = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"inventory"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/custom_reports/logistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_logistics_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"logistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/custom_reports/logistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_logistics = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"logistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/custom_reports/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_orders_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/custom_reports/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_orders = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/custom_reports/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_tickets_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/custom_reports/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_custom_reports_tickets = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_customer_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_customer = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/customers/check_buddy_upgrade_enabled(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_customers_check_buddy_upgrade_enabled_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"check_buddy_upgrade_enabled"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/customers/check_buddy_upgrade_enabled(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_customers_check_buddy_upgrade_enabled = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"check_buddy_upgrade_enabled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/customers/shipping_address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_customers_shipping_address_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"shipping_address"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/customers/shipping_address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_customers_shipping_address = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"shipping_address"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/customers/update_preapprovals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_customers_update_preapprovals_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"update_preapprovals"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/customers/update_preapprovals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_customers_update_preapprovals = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"update_preapprovals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/devices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_device_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"devices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/devices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_device = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"devices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/device_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_device_types_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"device_types"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/device_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_device_types = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"device_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_devices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_devices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/sns/email_bounce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_email_bounce_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"sns"],[2,[7,"/"],[2,[6,"email_bounce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/sns/email_bounce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_email_bounce = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"sns"],[2,[7,"/"],[2,[6,"email_bounce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/email_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_email_messages_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_messages"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/email_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_email_messages = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/email_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_email_reports_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_reports"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/email_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_email_reports = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_emails_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"emails"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_emails = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"emails"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/employee_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employee_file_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/employee_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employee_file = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/employee_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employee_files_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/employee_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employee_files = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employees_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_employees = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/empty_cart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_empty_cart_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"empty_cart"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/empty_cart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_empty_cart = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"empty_cart"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/git(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_git_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"git"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/git(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_git = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"git"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/git/change_id_exists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_git_change_id_exists_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"git"],[2,[7,"/"],[2,[6,"change_id_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/git/change_id_exists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_git_change_id_exists = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"git"],[2,[7,"/"],[2,[6,"change_id_exists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/inventories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_inventories_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"inventories"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/inventories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_inventories = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"inventories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/inventory_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_inventory_items_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"inventory_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/inventory_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_inventory_items = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"inventory_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_invoices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_invoices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/lifecycle_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/lifecycle_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/lifecycle_management/cancel_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management_cancel_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[2,[7,"/"],[2,[6,"cancel_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/lifecycle_management/cancel_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management_cancel_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[2,[7,"/"],[2,[6,"cancel_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/lifecycle_management/noaction_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management_noaction_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[2,[7,"/"],[2,[6,"noaction_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/lifecycle_management/noaction_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management_noaction_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[2,[7,"/"],[2,[6,"noaction_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/lifecycle_management/reassign_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management_reassign_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[2,[7,"/"],[2,[6,"reassign_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/lifecycle_management/reassign_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management_reassign_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[2,[7,"/"],[2,[6,"reassign_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/lifecycle_management/suspend_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management_suspend_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[2,[7,"/"],[2,[6,"suspend_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/lifecycle_management/suspend_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_lifecycle_management_suspend_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"lifecycle_management"],[2,[7,"/"],[2,[6,"suspend_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_locations_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_locations = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/logistics_management_option(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_logistics_management_option_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"logistics_management_option"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/logistics_management_option(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_logistics_management_option = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"logistics_management_option"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/manufacturers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_manufacturers_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"manufacturers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/manufacturers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_manufacturers = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"manufacturers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/messages/handle_reply(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_messages_handle_reply_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/messages/handle_reply(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_messages_handle_reply = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/messages/handle_verizon_pin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_messages_handle_verizon_pin_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_verizon_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/messages/handle_verizon_pin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_messages_handle_verizon_pin = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_verizon_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_checkin_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_checkin = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_dashboard_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_dashboard = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_device_registration_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_device_registration = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_devices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_devices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_initialize_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_initialize = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_settings_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/mobile/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_phone_lines_settings = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/mobile/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_requests_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/mobile/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_mobile_requests = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_notes_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_notes = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_order_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_order = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/order_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_order_updates_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"order_updates"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/order_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_order_updates = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"order_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_orders_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_orders = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_devices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_devices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_device_colors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_colors_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_colors"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_device_colors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_colors = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_colors"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_device_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_conditions_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_conditions"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_device_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_conditions = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_conditions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_device_models(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_models_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_models"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_device_models(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_models = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_models"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_device_statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_statuses_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_device_statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_statuses = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_device_storages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_storages_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_storages"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_device_storages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_device_storages = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_device_storages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_devices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_devices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_devices/imei_lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_devices_imei_lookup_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[6,"imei_lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_devices/imei_lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_devices_imei_lookup = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[6,"imei_lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_devices/set_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_devices_set_status_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[6,"set_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_devices/set_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_devices_set_status = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[6,"set_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_line_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_line = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines/average_usages_for(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_average_usages_for_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"average_usages_for"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines/average_usages_for(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_average_usages_for = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"average_usages_for"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_checkin_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_checkin = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_dashboard_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_dashboard = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_device_registration_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_device_registration = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_devices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_devices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines/etf_for_line(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_etf_for_line_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_for_line"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines/etf_for_line(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_etf_for_line = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_for_line"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines/has_device(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_has_device_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"has_device"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines/has_device(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_has_device = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"has_device"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_initialize_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_initialize = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines/set_buddy_upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_set_buddy_upgrade_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"set_buddy_upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines/set_buddy_upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_set_buddy_upgrade = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"set_buddy_upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines/set_service_spare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_set_service_spare_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"set_service_spare"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines/set_service_spare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_set_service_spare = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"set_service_spare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines/set_vip(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_set_vip_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"set_vip"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines/set_vip(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_set_vip = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"set_vip"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_settings_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_settings = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines/valid_line(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_valid_line_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"valid_line"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines/valid_line(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_valid_line = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"valid_line"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_lines/validate_bulk_selection(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_validate_bulk_selection_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"validate_bulk_selection"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_lines/validate_bulk_selection(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_lines_validate_bulk_selection = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"validate_bulk_selection"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/phone_models(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_models_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_models"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/phone_models(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_phone_models = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_models"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/recents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recents_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"recents"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/recents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recents = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"recents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_line_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_line = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/get_approval_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines_get_approval_progress_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"get_approval_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/get_approval_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines_get_approval_progress = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"get_approval_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/save_approval_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines_save_approval_progress_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"save_approval_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/save_approval_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines_save_approval_progress = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"save_approval_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/save_approvals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines_save_approvals_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"save_approvals"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/save_approvals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines_save_approvals = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"save_approvals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/withdraw_approvals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines_withdraw_approvals_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"withdraw_approvals"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/recommendation_phone_lines/withdraw_approvals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recommendation_phone_lines_withdraw_approvals = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"withdraw_approvals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/request_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_request_types_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"request_types"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/request_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_request_types = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"request_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_requests_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_requests = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/shipments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_shipment_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/shipments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_shipment = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/shipments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_shipments_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/shipments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_shipments = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/shipping_speeds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_shipping_speeds_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"shipping_speeds"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/shipping_speeds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_shipping_speeds = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"shipping_speeds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/ticket_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_ticket_updates_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ticket_updates"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/ticket_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_ticket_updates = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ticket_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tickets_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tickets = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/tips_and_tricks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tips_and_trick_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tips_and_tricks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/tips_and_tricks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tips_and_trick = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tips_and_tricks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/tips_and_tricks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tips_and_tricks_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tips_and_tricks"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/tips_and_tricks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tips_and_tricks = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tips_and_tricks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/tips_and_tricks_icons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tips_and_tricks_icons_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tips_and_tricks_icons"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/tips_and_tricks_icons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tips_and_tricks_icons = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tips_and_tricks_icons"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_user_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_user = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/users/cart_contents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_cart_contents_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cart_contents"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/users/cart_contents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_cart_contents = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cart_contents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/users/lock_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_lock_users_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"lock_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/users/lock_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_lock_users = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"lock_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/users/log_browser_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_log_browser_info_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"log_browser_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/users/log_browser_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_log_browser_info = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"log_browser_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/users/unlock_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_unlock_users_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/users/unlock_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_unlock_users = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/users/valid_username(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_valid_username_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"valid_username"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/users/valid_username(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_users_valid_username = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"valid_username"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/messages/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/messages/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1 = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/sns/email_bounce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_email_bounce_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"sns"],[2,[7,"/"],[2,[6,"email_bounce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/sns/email_bounce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_email_bounce = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"sns"],[2,[7,"/"],[2,[6,"email_bounce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/messages/handle_reply(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_messages_handle_reply_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/messages/handle_reply(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_messages_handle_reply = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_checkin_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_checkin = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_dashboard_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_dashboard = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_device_registration_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_device_registration = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_devices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_devices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_initialize_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_initialize = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_settings_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_phone_lines_settings = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/messages/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/messages/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2 = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/devices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_device_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"devices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/devices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_device = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"devices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_devices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_devices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/sns/email_bounce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_email_bounce_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"sns"],[2,[7,"/"],[2,[6,"email_bounce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/sns/email_bounce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_email_bounce = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"sns"],[2,[7,"/"],[2,[6,"email_bounce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_employees_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_employees = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/inventory_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_inventory_items_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"inventory_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/inventory_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_inventory_items = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"inventory_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_invoices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_invoices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/messages/handle_reply(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_messages_handle_reply_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/messages/handle_reply(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_messages_handle_reply = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/messages/handle_verizon_pin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_messages_handle_verizon_pin_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_verizon_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/messages/handle_verizon_pin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_messages_handle_verizon_pin = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"handle_verizon_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_checkin_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_checkin = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_dashboard_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_dashboard = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_device_registration_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_device_registration = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_devices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_devices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_initialize_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_initialize = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_settings_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/mobile/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_phone_lines_settings = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/mobile/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_requests_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/mobile/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_mobile_requests = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"mobile"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_orders_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_orders = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_line_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_line = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_checkin_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/phone_lines/checkin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_checkin = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"checkin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_dashboard_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/phone_lines/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_dashboard = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_device_registration_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/phone_lines/device_registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_device_registration = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"device_registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_devices_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/phone_lines/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_devices = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_initialize_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/phone_lines/initialize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_initialize = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"initialize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_settings_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/phone_lines/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_phone_lines_settings = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/recents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_recents_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"recents"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/recents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_recents = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"recents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_requests_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_requests = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/shipments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_shipment_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/shipments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_shipment = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/shipments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_shipments_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/shipments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_shipments = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_tickets_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_tickets = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_v2_phone_line_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_v2_phone_line = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_v2_phone_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_v2_phone_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2_1/phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2point1_v2point1_phone_line_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2_1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2_1/phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2point1_v2point1_phone_line = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2_1"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2_1/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2point1_v2point1_phone_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2_1"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2_1/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2point1_v2point1_phone_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2_1"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /approvals/approve(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approvals_approve_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /approvals/approve(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approvals_approve = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /approvals/approve_or_deny(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approvals_approve_or_deny_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[6,"approve_or_deny"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /approvals/approve_or_deny(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approvals_approve_or_deny = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[6,"approve_or_deny"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /approvals/reject(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approvals_reject_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /approvals/reject(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approvals_reject = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts/aws_chat_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const aws_chat_token_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"aws_chat_token"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/aws_chat_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const aws_chat_token_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"aws_chat_token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bill_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /bills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bill = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bills_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bills"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /bill_invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bills_invoices_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bill_invoices"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /bills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bills = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bills"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /bills/bills_for(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bills_bills_for_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[6,"bills_for"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /bills/bills_for(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bills_bills_for = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[6,"bills_for"],[1,[2,[8,"."],[3,"format"]]]]]]]);


/**
 * Generates rails route to
 * /carriers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bill_invoice_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bill_invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /carriers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bill_invoice = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bill_invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /requests/block_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_service_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"block_service"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/block_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_service_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"block_service"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/shipments/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_api_shipment_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/shipments/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_api_shipment = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/shipments/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_api_v2_shipment_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/shipments/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_api_v2_shipment = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /carriers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const carrier_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"carriers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /carriers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const carrier = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"carriers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /change_recommendations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /change_recommendations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /change_recommendations/_prev_rec_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations__prev_rec_table_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"_prev_rec_table"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /change_recommendations/_prev_rec_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations__prev_rec_table = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"_prev_rec_table"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /product_feedbacks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const product_feedback_path = __jsr.r(
    { id: { r: true }, format: {} },
    [2, [7, "/"], [2, [6, "product_feedbacks"], [2, [7, "/"], [2, [3, "id"], [1, [2, [8, "."], [3, "format"]]]]]]],
    true
  );
  
  /**
   * Generates rails route to
   * /product_feedbacks(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  export const product_feedbacks_path = __jsr.r(
    { format: {} },
    [2, [7, "/"], [2, [6, "product_feedbacks"], [1, [2, [8, "."], [3, "format"]]]]]
  );

/**
 * Generates rails route to
 * /change_recommendations/_recs_chart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations__recs_chart_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"_recs_chart"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /change_recommendations/_recs_chart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations__recs_chart = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"_recs_chart"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /change_recommendations/_recs_summary_pg(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations__recs_summary_pg_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"_recs_summary_pg"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /change_recommendations/_recs_summary_pg(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations__recs_summary_pg = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"_recs_summary_pg"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /change_recommendations/begin_review(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations_begin_review_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"begin_review"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /change_recommendations/begin_review(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations_begin_review = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"begin_review"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /change_recommendations/prev_recs_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations_prev_recs_status_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"prev_recs_status"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /change_recommendations/prev_recs_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_recommendations_prev_recs_status = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"change_recommendations"],[2,[7,"/"],[2,[6,"prev_recs_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/jobs/:id/check_job_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_job_status_api_job_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_job_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/jobs/:id/check_job_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_job_status_api_job = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_job_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /commerce/:platform(.:format)
 * @param {any} platform
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const commerce_url = __jsr.r({"platform":{"r":true},"format":{"d":"xml"}}, [2,[7,"/"],[2,[6,"commerce"],[2,[7,"/"],[2,[3,"platform"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /commerce/:platform(.:format)
 * @param {any} platform
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const commerce = __jsr.r({"platform":{"r":true},"format":{"d":"xml"}}, [2,[7,"/"],[2,[6,"commerce"],[2,[7,"/"],[2,[3,"platform"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /commerce/save_incoming_doc(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const commerce_save_incoming_doc_url = __jsr.r({"format":{"d":"xml"}}, [2,[7,"/"],[2,[6,"commerce"],[2,[7,"/"],[2,[6,"save_incoming_doc"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /commerce/save_incoming_doc(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const commerce_save_incoming_doc = __jsr.r({"format":{"d":"xml"}}, [2,[7,"/"],[2,[6,"commerce"],[2,[7,"/"],[2,[6,"save_incoming_doc"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /communication_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const communication_management_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"communication_management"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /communication_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const communication_management = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"communication_management"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/employee_files/:id/confirm_upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_upload_api_employee_file_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm_upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/employee_files/:id/confirm_upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_upload_api_employee_file = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm_upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /confirmations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmation_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /confirmations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /confirmations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmations_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /confirmations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /confirmations/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmations_user_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /confirmations/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmations_user = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const countries_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const countries = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /custom_columns/contact/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_columns_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_columns"],[2,[7,"/"],[2,[6,"contact"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /custom_columns/contact/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_columns_contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_columns"],[2,[7,"/"],[2,[6,"contact"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /custom_columns/customer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_columns_customer_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_columns"],[2,[7,"/"],[2,[6,"customer"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /custom_columns/customer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_columns_customer = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_columns"],[2,[7,"/"],[2,[6,"customer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /custom_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_report_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /custom_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_report = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /custom_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_reports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_reports"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /custom_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_reports = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /custom_reports/choose_category(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_reports_choose_category_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"choose_category"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /custom_reports/choose_category(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_reports_choose_category = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"choose_category"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer_order_type_message(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_order_type_message_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer_order_type_message"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /customer_order_type_message(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_order_type_message = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer_order_type_message"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customer_order_type_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_order_type_messages_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer_order_type_messages"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /customer_order_type_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_order_type_messages = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer_order_type_messages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contacts/customer_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_users_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"customer_users"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/customer_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_users_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"customer_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customers/unbilled_usage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_unbilled_usage_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"unbilled_usage"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /customers/unbilled_usage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_unbilled_usage = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"unbilled_usage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cvda(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /cvda(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /cvda/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_confirm_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /cvda/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_confirm = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cvda/confirmation_error(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_confirmation_error_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"confirmation_error"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /cvda/confirmation_error(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_confirmation_error = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"confirmation_error"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cvda/confirmation_success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_confirmation_success_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"confirmation_success"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /cvda/confirmation_success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_confirmation_success = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"confirmation_success"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cvda/signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_signup_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"signup"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /cvda/signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_signup = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"signup"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cvda/thankyou(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_thankyou_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"thankyou"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /cvda/thankyou(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cvda_thankyou = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cvda"],[2,[7,"/"],[2,[6,"thankyou"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts/:id/delegates(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delegates_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delegates"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/:id/delegates(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delegates_contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delegates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_download_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /documents/download_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_download_pdf_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"download_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /documents/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_download = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/employee_files/:id/download_error_report(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_error_report_api_employee_file_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_error_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/employee_files/:id/download_error_report(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_error_report_api_employee_file = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_error_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/employee_files/:id/download_original(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_original_api_employee_file_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_original"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/employee_files/:id/download_original(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_original_api_employee_file = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"employee_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_original"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/shipments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_shipment_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/shipments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_shipment = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/shipments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v2_shipment_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/shipments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v2_shipment = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /confirmations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_confirmation_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /confirmations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_confirmation = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contacts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /locations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_location_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /locations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_location = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contacts/:id/edit_notifications(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_notifications_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/:id/edit_notifications(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_notifications_contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /passwords/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_password_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /passwords/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_password = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /phone_devices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_phone_device_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /phone_devices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_phone_device = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/phone_lines/etf_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const etf_confirmation_punchout_phone_lines_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/phone_lines/etf_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const etf_confirmation_punchout_phone_lines = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /exporter/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_accounts_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_accounts = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/bills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_bill_url = __jsr.r({"id":{"r":true},"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/bills/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_bill = __jsr.r({"id":{"r":true},"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /exporter/bills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_bills_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"bills"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/bills(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_bills = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"bills"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/bills/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_bills_download_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/bills/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_bills_download = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"bills"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /exporter/contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_contacts_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_contacts = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/email_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_email_messages_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"email_messages"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/email_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_email_messages = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"email_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/lifecycle_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_lifecycle_management_index_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"lifecycle_management"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/lifecycle_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_lifecycle_management_index = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"lifecycle_management"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/lifecycle_recommendation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_lifecycle_recommendation_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"lifecycle_recommendation"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/lifecycle_recommendation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_lifecycle_recommendation = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"lifecycle_recommendation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_locations_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_locations = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_orders_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_orders = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/phone_devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_phone_devices_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"phone_devices"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/phone_devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_phone_devices = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"phone_devices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_phone_lines_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_phone_lines = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/recommendation_phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_recommendation_phone_lines_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/recommendation_phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_recommendation_phone_lines = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"recommendation_phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /exporter/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_tickets_url = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exporter/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exporter_tickets = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"exporter"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /help(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const help_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"help"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /help(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const help_index = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"help"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /impersonate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impersonate_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"impersonate"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /impersonate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impersonate = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"impersonate"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contacts/import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"import"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/international(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const international_punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"international"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/international(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const international_punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"international"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/international(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const international_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"international"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/international(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const international_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"international"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /kiwi/test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const kiwi_test_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"kiwi"],[2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /kiwi/test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const kiwi_test = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"kiwi"],[2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /lifecycle_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lifecycle_management_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lifecycle_management"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /lifecycle_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lifecycle_management_index = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lifecycle_management"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /locations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const location = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const locations_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const locations = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /login/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_verify_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /login/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_verify = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logout_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logout = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contacts/managers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const managers_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"managers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/managers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const managers_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"managers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mdm/intune/auth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mdm_intune_auth_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mdm"],[2,[7,"/"],[2,[6,"intune"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /mdm/intune/auth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mdm_intune_auth = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mdm"],[2,[7,"/"],[2,[6,"intune"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mdm/intune/enroll(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mdm_intune_enroll_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mdm"],[2,[7,"/"],[2,[6,"intune"],[2,[7,"/"],[2,[6,"enroll"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /mdm/intune/enroll(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mdm_intune_enroll = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mdm"],[2,[7,"/"],[2,[6,"intune"],[2,[7,"/"],[2,[6,"enroll"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mdm/intune/failure(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mdm_intune_failure_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mdm"],[2,[7,"/"],[2,[6,"intune"],[2,[7,"/"],[2,[6,"failure"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /mdm/intune/failure(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mdm_intune_failure = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mdm"],[2,[7,"/"],[2,[6,"intune"],[2,[7,"/"],[2,[6,"failure"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mdm/intune/success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mdm_intune_success_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mdm"],[2,[7,"/"],[2,[6,"intune"],[2,[7,"/"],[2,[6,"success"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /mdm/intune/success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mdm_intune_success = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mdm"],[2,[7,"/"],[2,[6,"intune"],[2,[7,"/"],[2,[6,"success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/missing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const missing_punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"missing"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/missing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const missing_punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"missing"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/approvals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_approval_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/approvals/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_approval = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/latest/shipments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_shipment_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/latest/shipments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_shipment = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"latest"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/shipments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v2_shipment_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v2/shipments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v2_shipment = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /confirmations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_confirmation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /confirmations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_confirmation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_contact_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_contact = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /custom_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_custom_report_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /custom_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_custom_report = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /locations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_location_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /locations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_location = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"locations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_order_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_order = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /passwords/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /passwords/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_password = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /phone_devices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_phone_device_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /phone_devices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_phone_device = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_request_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_request = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /session/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /session/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_session = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts/old_new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const old_new_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"old_new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/old_new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const old_new_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"old_new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts/orderables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orderables_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"orderables"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/orderables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orderables_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"orderables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /orders/checkout_review(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_checkout_review_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"checkout_review"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/checkout_review(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_checkout_review = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"checkout_review"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/create_new_shipping_label(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_create_new_shipping_label_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"create_new_shipping_label"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/create_new_shipping_label(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_create_new_shipping_label = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"create_new_shipping_label"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/create_upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_create_upgrade_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"create_upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/create_upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_create_upgrade = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"create_upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/devices_assignment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_devices_assignment_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"devices_assignment"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/devices_assignment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_devices_assignment = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"devices_assignment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/new_shipping_label(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_new_shipping_label_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"new_shipping_label"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/new_shipping_label(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_new_shipping_label = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"new_shipping_label"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/product_review_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_product_review_table_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"product_review_table"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/product_review_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_product_review_table = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"product_review_table"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/return_shipment_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_return_shipment_details_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"return_shipment_details"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/return_shipment_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_return_shipment_details = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"return_shipment_details"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/save_review_data_to_cart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_save_review_data_to_cart_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"save_review_data_to_cart"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/save_review_data_to_cart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_save_review_data_to_cart = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"save_review_data_to_cart"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orders/upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_upgrade_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /orders/upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const orders_upgrade = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /passwords/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /passwords/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password/sent
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_sent_url = __jsr.r({}, [2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[6,"sent"]]]], true);

/**
 * Generates rails route to
 * /password/sent
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_sent = __jsr.r({}, [2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[6,"sent"]]]]);

/**
 * Generates rails route to
 * /passwords(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passwords_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /passwords(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passwords = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /passwords/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passwords_edit_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /passwords/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passwords_edit = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /passwords/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passwords_user_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /passwords/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passwords_user = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /phone_devices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_device_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /phone_devices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_device = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /phone_device_catalog_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_device_catalog_items_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_device_catalog_items"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /phone_device_catalog_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_device_catalog_items = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_device_catalog_items"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /phone_devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_devices_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_devices"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /phone_devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_devices = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_devices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_line_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /phone_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_line = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /phone_lines/deactivate_devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_deactivate_devices_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"deactivate_devices"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /phone_lines/deactivate_devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_deactivate_devices = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"deactivate_devices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /phone_lines/etf_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_etf_confirmation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /phone_lines/etf_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_etf_confirmation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /phone_lines/lines_for(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_lines_for_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"lines_for"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /phone_lines/lines_for(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_lines_for = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"lines_for"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /phone_lines/old_index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_old_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"old_index"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /phone_lines/old_index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_old_index = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"old_index"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /phone_lines/phone_line_bill_breakdown(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_phone_line_bill_breakdown_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"phone_line_bill_breakdown"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /phone_lines/phone_line_bill_breakdown(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phone_lines_phone_line_bill_breakdown = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"phone_line_bill_breakdown"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/port(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const port_punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"port"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/port(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const port_punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"port"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/port(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const port_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"port"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/port(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const port_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"port"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/email_messages/preview_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_emails_api_email_messages_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_messages"],[2,[7,"/"],[2,[6,"preview_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/email_messages/preview_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_emails_api_email_messages = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_messages"],[2,[7,"/"],[2,[6,"preview_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /profile_registration/:uuid(.:format)
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_registration_url = __jsr.r({"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[3,"uuid"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /profile_registration/:uuid(.:format)
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_registration = __jsr.r({"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[3,"uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /profile_registration/confirmation/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_registration_confirm_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /profile_registration/confirmation/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_registration_confirm = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /profile_registration/email_sent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_registration_email_sent_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[6,"email_sent"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /profile_registration/email_sent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_registration_email_sent = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[6,"email_sent"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /profile_registration/registration/:uuid/:email(.:format)
 * @param {any} uuid
 * @param {any} email
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_registration_register_url = __jsr.r({"uuid":{"r":true},"email":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[3,"uuid"],[2,[7,"/"],[2,[3,"email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /profile_registration/registration/:uuid/:email(.:format)
 * @param {any} uuid
 * @param {any} email
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_registration_register = __jsr.r({"uuid":{"r":true},"email":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[3,"uuid"],[2,[7,"/"],[2,[3,"email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/orders/:id/updates(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/orders/:id/updates(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/accessories/:id/add_to_cart(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accessories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_to_cart"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/accessories/:id/add_to_cart(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accessories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_to_cart"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/accessories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_accessories_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accessories"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/accessories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_accessories = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accessories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_accounts_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_accounts = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/accounts/for_dropdown(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_accounts_for_dropdown_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"for_dropdown"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/accounts/for_dropdown(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_accounts_for_dropdown = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"for_dropdown"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/cart_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_cart_item_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"cart_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/cart_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_cart_item = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"cart_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/catalog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_catalog_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/catalog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_catalog = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/cold_inventories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_cold_inventories_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"cold_inventories"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/cold_inventories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_cold_inventories = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"cold_inventories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/contacts/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_contacts_employees_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/contacts/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_contacts_employees = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/contacts/is_entitled(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_contacts_is_entitled_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"is_entitled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/contacts/is_entitled(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_contacts_is_entitled = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"is_entitled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_customer_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_customer = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/customers/shipping_address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_customers_shipping_address_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"shipping_address"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/customers/shipping_address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_customers_shipping_address = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"shipping_address"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/empty_cart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_empty_cart_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"empty_cart"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/empty_cart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_empty_cart = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"empty_cart"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_notes_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_notes = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_order_url = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_order = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/phone_devices/imei_lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_phone_devices_imei_lookup_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[6,"imei_lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/phone_devices/imei_lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_phone_devices_imei_lookup = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_devices"],[2,[7,"/"],[2,[6,"imei_lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_phone_lines_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/phone_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_phone_lines = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/phone_lines/etf_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_phone_lines_etf_confirmation_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/phone_lines/etf_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_phone_lines_etf_confirmation = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/api/users/cart_contents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_users_cart_contents_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cart_contents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/api/users/cart_contents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_api_users_cart_contents = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cart_contents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/cart/devices_assignment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_cart_devices_assignment_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"devices_assignment"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/cart/devices_assignment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_cart_devices_assignment = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"cart"],[2,[7,"/"],[2,[6,"devices_assignment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /punchout/expired(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_expired_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"expired"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/expired(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_expired = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"expired"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /punchout/orders/back_to_ariba(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_orders_back_to_ariba_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"back_to_ariba"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/orders/back_to_ariba(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_orders_back_to_ariba = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"back_to_ariba"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_orders_new_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/orders/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_orders_new = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/orders/process_changes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_orders_process_changes_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"process_changes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/orders/process_changes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_orders_process_changes = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"process_changes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/orders/upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_orders_upgrade_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/orders/upgrade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_orders_upgrade = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/phone_device_catalog_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_phone_device_catalog_items_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"phone_device_catalog_items"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/phone_device_catalog_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_phone_device_catalog_items = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"phone_device_catalog_items"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /punchout/phone_lines/etf_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_phone_lines_etf_confirmation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/phone_lines/etf_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_phone_lines_etf_confirmation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"phone_lines"],[2,[7,"/"],[2,[6,"etf_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/:platform/session/new(.:format)
 * @param {any} platform
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_platform_url = __jsr.r({"platform":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[3,"platform"],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/:platform/session/new(.:format)
 * @param {any} platform
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_platform = __jsr.r({"platform":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[3,"platform"],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/buy_accessories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_buy_accessories_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"buy_accessories"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/buy_accessories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_buy_accessories = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"buy_accessories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_edit_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_edit = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_home_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_home = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/inspect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_inspect_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"inspect"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/inspect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_inspect = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"inspect"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/new_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_new_service_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"new_service"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/new_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_new_service = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"new_service"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/port(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_port_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"port"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/port(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_port = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"port"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/review_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_review_order_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"review_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/review_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_review_order = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"review_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/service_activation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_service_activation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/service_activation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_service_activation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/service_activation_checkout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_service_activation_checkout_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation_checkout"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/service_activation_checkout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_service_activation_checkout = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation_checkout"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/service_activation_review_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_service_activation_review_table_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation_review_table"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/service_activation_review_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_service_activation_review_table = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation_review_table"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/upgrade_device(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_upgrade_device_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"upgrade_device"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/upgrade_device(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_requests_upgrade_device = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"upgrade_device"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /punchout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_root = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /punchout/session/impersonate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_session_impersonate_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"impersonate"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/session/impersonate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_session_impersonate = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"impersonate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/session/keep_alive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_session_keep_alive_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"keep_alive"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/session/keep_alive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_session_keep_alive = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"keep_alive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/session/time_remaining(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_session_time_remaining_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"time_remaining"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/session/time_remaining(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_session_time_remaining = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"time_remaining"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/shipments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_shipment_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/shipments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_shipment = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/test/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_customers_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/test/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_customers = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/test/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_orders_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/test/orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_orders = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/test/punchout_url(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_punchout_url_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"punchout_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/test/punchout_url(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_punchout_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"punchout_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/test/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_tickets_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/test/tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_tickets = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/test/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_users_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/test/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_test_users = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"test"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/ticket_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_ticket_updates_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"ticket_updates"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/ticket_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const punchout_ticket_updates = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"ticket_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_url = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_url = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_url = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_url = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_url = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_url = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/reassign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reassign_punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"reassign"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/reassign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reassign_punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"reassign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /recommendation_phone_lines/ajax_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendation_phone_lines_ajax_table_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"ajax_table"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /recommendation_phone_lines/ajax_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendation_phone_lines_ajax_table = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"ajax_table"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /recommendation_phone_lines/feature_changes_ajax_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendation_phone_lines_feature_changes_ajax_table_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"feature_changes_ajax_table"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /recommendation_phone_lines/feature_changes_ajax_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendation_phone_lines_feature_changes_ajax_table = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"feature_changes_ajax_table"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /recommendation_phone_lines/plan_changes_ajax_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendation_phone_lines_plan_changes_ajax_table_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"plan_changes_ajax_table"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /recommendation_phone_lines/plan_changes_ajax_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommendation_phone_lines_plan_changes_ajax_table = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recommendation_phone_lines"],[2,[7,"/"],[2,[6,"plan_changes_ajax_table"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /release_notes/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const release_notes_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"release_notes"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /release_notes/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const release_notes_index = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"release_notes"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts/:id/remove_delegate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_delegate_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_delegate"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/:id/remove_delegate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_delegate_contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_delegate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reports = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /requests/service_activation_review_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requests_service_activation_review_table_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation_review_table"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/service_activation_review_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requests_service_activation_review_table = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation_review_table"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/email_messages/resend_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_email_api_email_messages_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_messages"],[2,[7,"/"],[2,[6,"resend_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/email_messages/resend_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_email_api_email_messages = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_messages"],[2,[7,"/"],[2,[6,"resend_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_url = __jsr.r({}, [7,"/"], true);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /contacts/sample_template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sample_template_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"sample_template"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/sample_template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sample_template_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"sample_template"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/email_messages/save_decisions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_decisions_api_email_messages_url = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_messages"],[2,[7,"/"],[2,[6,"save_decisions"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/email_messages/save_decisions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_decisions_api_email_messages = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"email_messages"],[2,[7,"/"],[2,[6,"save_decisions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /security_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const security_groups_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"security_groups"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /security_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const security_groups = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"security_groups"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /profile_registration/send_verification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_verification_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[6,"send_verification"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /profile_registration/send_verification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_verification = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile_registration"],[2,[7,"/"],[2,[6,"send_verification"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /requests/service_activation_checkout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_activation_checkout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation_checkout"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/service_activation_checkout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_activation_checkout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation_checkout"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/service_activation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_activation_punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/service_activation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_activation_punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/service_activation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_activation_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/service_activation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const service_activation_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"service_activation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /session(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /session(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /session/keep_alive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_keep_alive_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"keep_alive"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /session/keep_alive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_keep_alive = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"keep_alive"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /session/keep_login_alive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_keep_login_alive_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"keep_login_alive"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /session/keep_login_alive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_keep_login_alive = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"keep_login_alive"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /session/time_remaining(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_time_remaining_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"time_remaining"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /session/time_remaining(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_time_remaining = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"session"],[2,[7,"/"],[2,[6,"time_remaining"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /set_dashboard_to_default(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_dashboard_to_default_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"set_dashboard_to_default"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /set_dashboard_to_default(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_dashboard_to_default = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"set_dashboard_to_default"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shipments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shipment_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /shipments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shipment = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shipments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /side_overlay_content(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const side_overlay_content_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"side_overlay_content"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /side_overlay_content(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const side_overlay_content = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"side_overlay_content"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /site_configuration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_configuration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"site_configuration"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /site_configuration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_configuration = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"site_configuration"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/support(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const support_punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"support"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/support(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const support_punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"support"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/support(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const support_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"support"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/support(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const support_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"support"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/suspend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const suspend_punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"suspend"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/suspend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const suspend_punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"suspend"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/suspend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const suspend_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"suspend"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/suspend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const suspend_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"suspend"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts/:id/team_members(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const team_members_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"team_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/:id/team_members(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const team_members_contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"team_members"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contacts/terminate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terminate_contacts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"terminate"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/terminate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terminate_contacts = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"terminate"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /test_all_pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_all_pages_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"test_all_pages"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /test_all_pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_all_pages = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"test_all_pages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /test_punchout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_punchout_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"test_punchout"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /test_punchout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_punchout = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"test_punchout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticket_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /tickets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ticket = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tickets_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /tickets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tickets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tickets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tips_and_tricks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tips_and_tricks_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tips_and_tricks"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /tips_and_tricks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tips_and_tricks = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tips_and_tricks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/:id/terms-of-service(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tos_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"terms-of-service"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /user/:id/terms-of-service(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tos = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"terms-of-service"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/:id/terms-of-service(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tos_agree_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"terms-of-service"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /user/:id/terms-of-service(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tos_agree = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"terms-of-service"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /punchout/requests/unsuspend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsuspend_punchout_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"unsuspend"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /punchout/requests/unsuspend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsuspend_punchout_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"punchout"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"unsuspend"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /requests/unsuspend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsuspend_requests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"unsuspend"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /requests/unsuspend(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsuspend_requests = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"unsuspend"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /update_dashlet_positions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_dashlet_positions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_dashlet_positions"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /update_dashlet_positions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_dashlet_positions = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_dashlet_positions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /update_dashlets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_dashlets_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_dashlets"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /update_dashlets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_dashlets = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_dashlets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contacts/:id/update_delegate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_delegate_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_delegate"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/:id/update_delegate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_delegate_contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_delegate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /update_layout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_layout_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_layout"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /update_layout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_layout = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_layout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /update_preferences(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_preferences_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_preferences"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /update_preferences(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_preferences = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_preferences"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_url = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_user_confirmation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_user_confirmation = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_guides/:user_guide(.:format)
 * @param {any} user_guide
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_guide_url = __jsr.r({"user_guide":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_guides"],[2,[7,"/"],[2,[3,"user_guide"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /user_guides/:user_guide(.:format)
 * @param {any} user_guide
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_guide = __jsr.r({"user_guide":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_guides"],[2,[7,"/"],[2,[3,"user_guide"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/update_user_preferences(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_update_user_preferences_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update_user_preferences"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/update_user_preferences(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_update_user_preferences = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update_user_preferences"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contacts/:id/validate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_contact_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"validate"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /contacts/:id/validate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_contact = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"validate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

